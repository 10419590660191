#untappd-div {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.rotation {
    font-size: 2.222em;
    font-weight: bolder;
}

.visit-untappd {
    font-size: 1.5em;
    font-weight: lighter;
}

.view-untappd {
    background-color: #C1D72D;
    border-radius: 20px;
    padding: 10px 50px;

    font-size: .7em;
    font-weight: bold;

    box-shadow: -5px 5px 5px #888888;
}

.view-untappd:hover {
    cursor: pointer;
}

.view-untappd:focus {
    outline: none;
    border: none;
}

.view-untappd:active {
    background-color: black;
    color: #C1D72D;
}

.right-arrow {
    font-size: .9em;
}

h2,
h4, 
button {
    margin: 1rem 0;
}