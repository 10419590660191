#location-hours-header {
    font-size: 3.75rem;
    text-align: center;
}

#get-directions {
    color: olivedrab;
}

#phone-number {
    color: olivedrab;
}

.hours {
    padding-top: 4rem;
}

.main-container {
    margin-top: 2rem;
}

.content-container {
    margin-top: 3rem;
    display: flex;
}

#map {
    width: 600px;
    height: 450px;
    border: 0;
    margin: 2rem 0;
}

@media screen and (max-width: 769px) {
    .map-container-nonmobile {
        display: none;
    }

    #location-hours-header {
        font-size: 3.05rem;
        text-align: center;
    }    
}

/* @media screen and (min-width: 768px) {
} */
