#my-hero {
    background-color: olive;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./images/hopsandvines.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    padding: 25px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
}

.bottom {
    margin-top: auto;
}

.address,
.logo,
.social,
.address a {
    color: white;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
}

.map-pin-icon,
.phone-icon {
    height: 1em;
    width: 1em;
    margin-right: 4px;
}

.hops-and-vines-logo {
    height: 8.33em;
}

.facebook-logo,
.untappd-logo,
.yelp-logo {
    margin: 7.5px;
}

.welcome-message {
    font-size: 2.5em;
    line-height: 2.11em;
    letter-spacing: .05em;
    text-align: center;
    padding-top: 125px;
}

.glass-grapes {
    align-items: center;
}

.glass,
.beer-glass {
    margin: 10px 7.5px 10px 0;
    height: 1.5em;
    fill: white;
}

.grapes,
.wine-bottle,
.wine-glass {
    margin: 10px 0 10px 7.5px;
    height: 1.5em;
    fill: white;
}

.located {
    font-size: 1.25em;
    line-height: 1.75em;
}

.mouse-div {
    display: flex;
    flex-direction: row;
    flex-grow: 1; 
    align-items: flex-end;
    justify-content: center;
}

.mouse {
    height: 2.75em;
    color: black;
}

@media screen and (max-width: 769px) {
    .hops-and-vines-logo {
        width: 55%;
        height: 20%;
    }

    #my-hero {
        height: 600px;
    }

    .welcome-message {
        font-size: 1.55rem;
    }

    .located {
        font-size: 0.75em;
    }
}
