.navbar {
    background-color: black;
    width: 100vw;
}

.dropdown-menu {
    background-color: #c1d72d;
}

#navbar-link {
    padding-right: 0.7rem;
    color: #c1d72d;
    text-decoration: none;
}

#dropdown-text{
    padding-right: 0.7rem;
    color: black;
    text-decoration: none;
}

#dropdown-text:hover{
    color: white;
}

#dropdown-link {
    margin: 0;
}

#dropdown-link:hover {
    background-color: transparent;
}

#navbar-link:hover {
    color: olivedrab;
}

#basic-nav-dropdown {
    color: #c1d72d;
}

.dropdown-toggle:after {
    content: none 
}

.facebook-logo {
    height: 1.5em;
    fill: #316FF6;
}

.untappd-logo {
    height: 1.5em;
    fill: #F2CB07;
}

.yelp-logo {
    height: 1.5em;
    fill: #FF1A1A;
}

@media screen and (max-width: 769px) {
    .social-media,
    .full-screen {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .dropdown {
        display: none;
    }
}
