#footer {
    display: flex;
    margin-top: auto;
    height: 100px; 
    background-color: olivedrab;
    align-items: center;
    justify-content: center;
}

a {
    margin: 0 10px;
}

img {
    height: 2rem;
}

.facebook-logo {
    height: 1.5em;
    fill: #316FF6;
}

.untappd-logo {
    height: 1.5em;
    fill: #F2CB07;
}

.yelp-logo {
    height: 1.5em;
    fill: #FF1A1A;
}

@media only screen and (min-width: 768px) {
    .mobile-social {
        display: none;
    }
}
