.main-menu-container {
    align-content: center;
    padding-top: 2.5rem;
}

.menu-header {
    text-align: center;
    font-size: 3.75rem;
}

.menu-item-list {
    list-style: none;
    font-size: 3rem;
    line-height: 2.5rem;
}

.menu-price-list {
    list-style: none;
    font-size: 3rem;
    line-height: 2.5rem;
}

.sauces {
    text-align: center;
}

.hop-image {
    height: 9em;
}

.ui.equal.width.grid {
    margin: 0;
    height: 100%;
}

@media screen and (max-width: 769px) {
    .main-menu-container {
        width: 90%;
    }
}
